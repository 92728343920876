import axios from 'axios'
import { baseUrl } from '../../constants';
const apiInstance = axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  
  apiInstance.defaults.headers.post['Content-Type'] = 'application/json';
  export default apiInstance;
  
//   import Axios from 'axios';

// let baseURL=process.env.REACT_APP_BASE_URL;
// if (baseURL===undefined) {
//   console.log("Cannot get base URL from env vars.");
//   baseURL="https://i73k8oyibj.execute-api.us-east-1.amazonaws.com/prod";
//   // tic:enron:baseURL="https://c9ltdtfho8.execute-api.us-east-1.amazonaws.com/prod";
//   // market:baseURL="https://wk250pc3e9.execute-api.us-east-1.amazonaws.com/public";
//   // market(new):baseURL="https://i73k8oyibj.execute-api.us-east-1.amazonaws.com/prod";
//   // tic:cfday:baseURL="https://65xeqy9xv6.execute-api.us-east-1.amazonaws.com/prod";
// }

// const instance = Axios.create({
//   baseURL: baseURL,
//   headers: { Accept: 'application/json' }
// });

// instance.defaults.headers.post['Content-Type'] = 'application/json';

// export { instance };

