// apiService.js

import apiInstance from "../utils/apiInstance";

// const getUrlParameter = (name) => {
//     name = name.replace(/\[/g, '\\[').replace(/\]/g, '\\]');
//     const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
//     const results = regex.exec(window.location.search);
//     return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
// };

export const registerUser = async (userData) => {
  // console.log(userData,"userData")
  // const regToken = getUrlParameter('x-amzn-marketplace-token');
  // // const regToken ='MESC49DxkkV7c8jVMBL+6nthOuOYO5XYTrCOGAMSEmoiTJRa/6Gxs4EgwdikOqsfBIloJybWDnUPIdUkf1Vov96toivNK2mCCCokjqDTvT5uFfup9IRh4jU8z8QOzYBtlqLg3zUvENN167ougu1e4YDkvJp9ajzMDgOLPUWVUKTcqjMM0Ktdfw==';
  // if (!regToken) {
  //   alert('Marketplace registration token is missing. Please ensure you have a valid token.');
  // }

  // const data = { ...userData, regToken };
  console.log(userData, "data");

  try {
    const response = await apiInstance.post("register", userData);
    return response.data;
  } catch (error) {
    console.log(error, "error");
    throw error;
  }
};
export const testUserName = async (userdata) => {
  try {
    const response = await apiInstance.post("testing-username", userdata);
    return { response: response, error: null };
  } catch (error) {
    console.log(error);
    return { response: null, error: error };
  }
};
