import React from 'react';
// import RegistrationForm from './RegistrationForm';

import { Route , BrowserRouter ,Routes} from 'react-router-dom';

import EmailVerify from './components/emailVerify/emailVerify';
import Register from './components/register/register'


function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Register />}>  </Route>
      <Route path="/emailverify" element={<EmailVerify />}> </Route>
    
    </Routes>
  </BrowserRouter>
  );
}

export default App;
