import React from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import logo from "../../assets/images/logo.png";
import { registerUser, testUserName } from "../../services/apiServices";
import apiInstance from "../../utils/apiInstance";
import { resolveCustomerEndpoint } from "../../constants";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Amplify } from "aws-amplify";
import awsmobile from "../../aws-exports";
import { Auth } from "aws-amplify";

Amplify.configure({
  ...awsmobile,
  Auth: {
    region: awsmobile.aws_project_region,
    userPoolId: awsmobile.aws_user_pools_id,
    userPoolWebClientId: awsmobile.aws_user_pools_web_client_id,
  },
});

export function isEmpty(str) {
  return !str || 0 === str.length;
}
export const isError = (value) =>
  value === undefined || value === null || value === "" ? false : true;

export const useUrlParameter = (paramName) => {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split("&"),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");

    if (sParameterName[0] === paramName) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
  return undefined;
};

const validate = (values) => {
  var errors = {};
  [
    "username",
    "contactEmail",
    "contactPhone",
    "companyName",
    "RegistrationToken",
    "ProductCode",
    "CustomerIdentifier",
    "CustomerAWSAccountId",
    "password",
    "confirmPassword",
  ].forEach((k) => {
    if (isEmpty(values[k])) {
      errors[k] = "Required";
    }
  });

  if (values["password"] !== values["confirmPassword"]) {
    errors["password"] = "Password does not match!";
    errors["confirmPassword"] = "Password does not match!";
  }

  return errors;
};

const defaultValues = {
  username: "",
  contactEmail: "",
  emailCode: "",
  password: "",
  companyName: "",
  contactPerson: "",
  couponCode: "",
  username: "",
  contactPhone: "",
  confirmPassword: "",
  errors: {},
};
const countryCodes = [
  { code: "US", dialCode: "+1", label: "United States" },
  { code: "GB", dialCode: "+44", label: "United Kingdom" },
];

const SignUp = () => {
  const navigate = useNavigate();
  const [selectedCountryCode, setSelectedCountryCode] = React.useState(
    countryCodes[0].dialCode
  );
  console.log(selectedCountryCode, "selectedCountryCode");
  const handleCountryCodeChange = (event) => {
    setSelectedCountryCode(event.target.value);
  };
  const [state, setState] = React.useState({
    ...defaultValues,
    ProductCode: "",
    RegistrationToken: "",
    CustomerIdentifier: "",
    CustomerAWSAccountId: "",
    selectedCountryCode: "",
    companyName: "",
    contactPhone: "",
    contactEmail: "",
    contactPerson: "",
    couponCode: "",
    username: "",
    loading: false,
    submitting: false,
    disabled: true,
    error: "",
  });
  console.log(state, "state");

  const {
    username,
    password,
    RegistrationToken,
    confirmPassword,
    contactPerson,
    ProductCode,
    contactEmail,
    CustomerIdentifier,
    CustomerAWSAccountId,
    contactPhone,
    couponCode,
    emailCode,
    loading,
    companyName,
    submitting,
    errors,
  } = state;

  useEffect(() => {
    // Retrieve values from URL parameters
    let RegistrationToken = useUrlParameter("regToken");
    let queryParamCustomerId = useUrlParameter("CustomerIdentifier");
    let queryParamCustomerAWS = useUrlParameter("CustomerAWSAccountId");
    let queryParamProductCode = useUrlParameter("ProductCode");

    // Update the state with the retrieved values
    setState((prevState) => ({
      ...prevState,
      RegistrationToken,
      CustomerIdentifier: queryParamCustomerId,
      CustomerAWSAccountId: queryParamCustomerAWS,
      ProductCode: queryParamProductCode,
    }));
  }, []);

  const handleSubmit = async () => {
    const errors = validate(state);
    if (Object.keys(errors).length > 0) {
      setState((p) => ({ ...p, errors }));
    } else {
      setState((p) => ({ ...p, errors: {}, submitting: true }));

      try {
        const formattedPhoneNo = `${selectedCountryCode}${
          contactPhone.startsWith("0") ? contactPhone.substr(1) : contactPhone
        }`;
        const contactPersonValue = username;
        const data = {
          username,
          contactEmail,
          companyName,
          password,
          RegistrationToken,
          contactPerson: contactPersonValue,
          contactPhone: formattedPhoneNo,
          couponCode,
        };

        const cognitoResponse = await Auth.signUp({
          username: contactEmail, // Use email as username
          password: password,
          attributes: {
            email: contactEmail,
            phone_number: formattedPhoneNo,
          },
        });

        console.log("logs:cognitoResponse", { cognitoResponse });

        const response = await registerUser(data);
        console.log(response, "responseapi");
        if (response) {
          alert(`Successfully completed customer Registration`);
          handleReset();
          setState((p) => ({
            ...p,
            errors: {},
            submitting: false,
            error: "",
            state: null,
          }));
          console.log("Successfully completed customer signup", { response });
        }
      } catch (err) {
        alert(`Error creating user.${err.message}`);
        setState((p) => ({
          ...p,
          errors: {},
          submitting: false,
          error: "Error creating user!",
        }));
      }
    }
  };

  const handleReset = () => {
    setState((p) => ({ ...p, ...defaultValues }));
  };

  const handleTest = async () => {
    console.log("inputs:", {
      aws_account_id: `${state.CustomerAWSAccountId}`,
      username: `${state.username}`,
    });
    let userTestedRes = await testUserName({
      aws_account_id: `${state.CustomerAWSAccountId}`,
      username: `${state.username}`,
    });

    console.log("outputs:", userTestedRes);

    let res = userTestedRes?.response || userTestedRes?.error;
    console.log("res:",res)
    if (res.status === 200) {
      setState((prevState) => ({
        ...prevState,
        disabled: false,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        disabled: true,
        error: `${res.response.data || res.error}`,
      }));
    }
  };

  const handleChange = (id) => (e) => {
    if(id==="username"){
      setState((p)=>({...p,error:""}))
    }

    const value = e?.target?.value;
    setState((p) => ({ ...p, [id]: value, errors: {} }));
  };

  const disabled = loading || submitting;

  return (
    <Container maxWidth="md" className="mb-5">
      <Box>
        <img className="mx-auto" src={logo} alt="" width="170" height="170" />
      </Box>
      <Box sx={{ mt: 4, mb: 2 }}>
        <Typography variant="h5">Sign Up</Typography>
        <Divider />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="caption">Registration Info</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={isError(errors["RegistrationToken"])}
            helperText={errors["RegistrationToken"]}
            sx={{ width: "100%" }}
            fullwidth
            value={RegistrationToken}
            InputLabelProps={{ shrink: true }}
            label="Registration Token"
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={isError(errors["ProductCode"])}
            helperText={errors["ProductCode"]}
            sx={{ width: "100%" }}
            value={ProductCode}
            label="Product Code"
            InputLabelProps={{ shrink: true }}
            fullwidth
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={isError(errors["CustomerIdentifier"])}
            helperText={errors["CustomerIdentifier"]}
            sx={{ width: "100%" }}
            value={CustomerIdentifier}
            label="Customer Identifier"
            fullwidth
            InputLabelProps={{ shrink: true }}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={isError(errors["CustomerAWSAccountId"])}
            helperText={errors["CustomerAWSAccountId"]}
            sx={{ width: "100%" }}
            value={CustomerAWSAccountId}
            label="Customer AWS AccountId"
            fullwidth
            InputLabelProps={{ shrink: true }}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">
            Please complete the following information and click the sign up
            button.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={isError(errors["contactEmail"])}
            helperText={errors["contactEmail"]}
            onChange={handleChange("contactEmail")}
            sx={{ width: "100%" }}
            value={contactEmail}
            label="Email"
            fullwidth
            InputLabelProps={{ shrink: true }}
            required
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          {/* <TextField
            error={isError(errors['emailCode'])}
            helperText={errors['emailCode']}
            onChange={handleChange('emailCode')}
            sx={{ width: '100%' }}
            value={emailCode}
            label="Verification Code"
            fullwidth
            InputLabelProps={{ shrink: true }}
            required
            disabled={disabled}
          /> */}
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={isError(errors["companyName"])}
            helperText={errors["companyName"]}
            onChange={handleChange("companyName")}
            sx={{ width: "100%" }}
            value={companyName}
            label="companyName"
            fullwidth
            InputLabelProps={{ shrink: true }}
            required
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={isError(errors["username"])}
            helperText={errors["username"]}
            onChange={handleChange("username")}
            sx={{ width: "100%" }}
            value={username}
            label={(state?.error ? `${state?.error}` : "username")}
            fullwidth
            InputLabelProps={{ shrink: true }}
            required
            disabled={disabled}
          />
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" alignItems="center">
            <FormControl sx={{ minWidth: 120, marginRight: 2 }}>
              <InputLabel>Code</InputLabel>
              <Select
                value={selectedCountryCode}
                onChange={handleCountryCodeChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200, // Set the max height for the menu
                    },
                  },
                }}
              >
                {countryCodes.map((country) => (
                  <MenuItem key={country.code} value={country.dialCode}>
                    {`( ${country.dialCode})`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              error={isError(errors["contactPhone"])}
              helperText={errors["contactPhone"]}
              onChange={handleChange("contactPhone")}
              sx={{ width: "100%" }}
              value={contactPhone}
              label="Phone"
              InputLabelProps={{ shrink: true }}
              required
              disabled={disabled}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={isError(errors["password"])}
            helperText={errors["password"]}
            onChange={handleChange("password")}
            sx={{ width: "100%" }}
            value={password}
            label="Password"
            fullwidth
            InputLabelProps={{ shrink: true }}
            InputProps={{ type: "password" }}
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={isError(errors["confirmPassword"])}
            helperText={errors["confirmPassword"]}
            onChange={handleChange("confirmPassword")}
            sx={{ width: "100%" }}
            value={confirmPassword}
            label="Confirm Password"
            fullwidth
            InputLabelProps={{ shrink: true }}
            InputProps={{ type: "password" }}
            disabled={disabled}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ textAlign: "right" }}>
            <Button
              disabled={disabled}
              onClick={handleReset}
              variant="contained"
              size="large"
              color="warning"
            >
              Reset
            </Button>
            <Button
              sx={{ ml: 2 }}
              disabled={disabled}
              onClick={handleTest}
              variant="contained"
              size="large"
              color="warning"
            >
              Test
            </Button>
            <Button
              sx={{ ml: 2 }}
              disabled={state.disabled || disabled}
              onClick={handleSubmit}
              variant="contained"
              size="large"
            >
              Sign Up
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SignUp;
