import React from 'react';
import { Box, Button, Container, Divider, Grid, TextField, Typography } from '@mui/material';
// import Amplify, { Auth } from 'aws-amplify';
// import awsmobile from '../aws-exports';
// import { instance } from '../actions/axios_instance';
import { useNavigate } from 'react-router-dom';
// export const AWS = require('aws-sdk'); // Load the AWS SDK for Node.js

const getCompleteUrl = ({ username, name, email, customerIdentifier }) =>
  `https://2iyljeg486.execute-api.us-east-1.amazonaws.com/public/verify?username=${username}&email=${email}&emailCode=${emailCode}`;

const resolveCustomerEndpoint = 'https://2iyljeg486.execute-api.us-east-1.amazonaws.com/public/resolve';

// Initialize the Amazon Cognito credentials provider
// AWS.config.region = awsmobile.aws_project_region;
// AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//   IdentityPoolId: awsmobile.aws_cognito_identity_pool_id
// });

// Amplify.configure({
//   ...awsmobile,
//   Auth: {
//     region: awsmobile.aws_project_region,
//     userPoolId: awsmobile.aws_user_pools_id,
//     userPoolWebClientId: awsmobile.aws_user_pools_web_client_id
//   }
// });

export function isEmpty(str) {
  return !str || 0 === str.length;
}
export const isError = (value) =>
  value === undefined || value === null || value === '' ? false : true;

export const useUrlParameter = (paramName) => {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=');

    if (sParameterName[0] === paramName) {
      return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    }
  }
  return undefined;
};

// const validate = (values) => {
//   var errors = {};
//   [
//     'name',
//     'email',
//     //'ProductCode',
//     'CustomerIdentifier',
//     'CustomerAWSAccountId',
//     'password',
//     'confirmPassword'
//   ].forEach((k) => {
//     if (isEmpty(values[k])) {
//       errors[k] = 'Required';
//     }
//   });

//   /*if (values['ProductCode'] !== process.env.REACT_APP_PRODUCT_CODE) {
//     errors['ProductCode'] =
//       'Invlaid Product Code.  Must match environment variable REACT_APP_PRODUCT_CODE';
//   }*/

//   if (values['password'] !== values['confirmPassword']) {
//     errors['password'] = 'Password does not match!';
//     errors['confirmPassword'] = 'Password does not match!';
//   }
  
//   return errors;
// };

const defaultValues = {
  name: '',
  email: '',
  emailCode: '',
  phone: '',
  password: '',
  confirmPassword: '',
  errors: {}
};

const VerifyEmail = () => {
    const navigate=useNavigate()
  const [state, setState] = React.useState({
    ...defaultValues,
    username: '',
    email: '',
    emailCode: '',
    loading: false,
    submitting: false
  });

  const {
    username,
    email,
    emailCode,
    loading,
    submitting,
    errors
  } = state;

//   username = useUrlParameter('username');
//   email = useUrlParameter('email');

//   React.useEffect(() => {
//     const searchParams = new URLSearchParams(window.location.search);
//     const params = {};

//     for (const [key, value] of searchParams) {
//       params[key] = value;
//     }

//     if (isEmpty(username) && params.hasOwnProperty('username')) username=params['username'];
//     if (isEmpty(email) && params.hasOwnProperty('email')) email=params['email'];

//     if (isEmpty(email)) {
//       console.log('No email was given.');
//     } else {
//       setState((p) => ({
//         ...p,
//         username: username,
//         email: email
//       }));
//     }
//   }, [username,email,'']);

  const handleSubmit = async () => {
    // const errors = validate(state);
    // if (Object.keys(errors).length > 0) {
    //   setState((p) => ({ ...p, errors }));
    // } else {
    //   setState((p) => ({ ...p, errors: {}, submitting: true }));

      try {
        console.log('handleRegisterConfirmation');
        
                     console.log(email);
                 console.log(emailCode);
                     await Auth.confirmSignUp(email, emailCode);
                     alert(
                        'Successfully Verified user.'
                      );
                     navigate('/');
      } catch (err) {
        console.error(err);
        alert(`Error verifying user ${err.message}`);
        setState((p) => ({ ...p, errors: {}, submitting: false, error: 'Error verifying email.' }));
      }
    // }
  };

  const handleReset = () => {
    setState((p) => ({ ...p, ...defaultValues }));
  };

  const handleChange = (id) => (e) => {
    const value = e?.target?.value;
    setState((p) => ({ ...p, [id]: value, errors: {} }));
  };

  const disabled = loading || submitting;

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 2 }}>
        <Typography variant="h5"> Validate</Typography>
        <Divider />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="caption">
            Please add the verification code sent to your email in the following box and click the verify button.
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={isError(errors['email'])}
            helperText={errors['email']}
            onChange={handleChange('email')}
            sx={{ width: '100%' }}
            value={email}
            label="Email"
            fullwidth
            InputLabelProps={{ shrink: true }}
            required
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={isError(errors['emailCode'])}
            helperText={errors['emailCode']}
            onChange={handleChange('emailCode')}
            sx={{ width: '100%' }}
            value={emailCode}
            label="Verification Code"
            fullwidth
            InputLabelProps={{ shrink: true }}
            required
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ textAlign: 'right' }}>
            <Button
            //   disabled={disabled}
              onClick={handleReset}
              variant="contained"
              size="large"
              color="warning">
              Resent
            </Button>
            <Button
              sx={{ ml: 2 }}
            //   disabled={disabled}
              onClick={handleSubmit}
              variant="contained"
              size="large">
              Verify
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default VerifyEmail;

